.modalRoot {
    display: flex;
    padding: 4rem 6rem;
}
.paperRoot {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: rgb(18, 18, 18) !important;
    padding: 1rem;
}

.paperRoot > div {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(200, 200, 200);
}

.chatMsg {
    color: rgb(230, 230, 230);
}

.gridRoot {
    display: flex;
    flex-grow: 1;
}
.gridRoot > div {
    height: 100%;
}

a {
    text-decoration: none;
    color: inherit;
}

abbr {
    text-decoration: none;
}