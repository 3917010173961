th {
    font-weight: bold!important;
}

.reportActionEditorModal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.reportActionEditorModal > .paper {
    padding: 1rem;
    margin: 1rem;
    flex-grow: 1;
}

.formContainer > div:first-child {
    padding: 0;
}
.formContainer > div {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}